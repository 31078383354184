import React from "react";
import Layout from "../components/layout";
import {
  useNetlifyForm,
  NetlifyFormProvider,
  NetlifyFormComponent,
  Honeypot,
} from "react-netlify-forms";
import { useForm } from "react-hook-form";
import { StyledH1, StyledSectionWrapper } from "../theme/styles";
import styled from "styled-components";
import t from "../theme/theme";
import Mailchimp from "react-mailchimp-form";

const StyledContact = styled.div`
  padding-top: 15vh;
  padding-right: ${t.spacing(2)};
  width: 100%;
`;

const StyledContactWrapper = styled(StyledSectionWrapper)`
  height: auto;
  &:not(:first-child) {
    padding-top: ${t.spacing(3)};
    h1 {
      padding-top: 0;
    }
  }
`;

const StyledForm = styled(Mailchimp)`
  label {
    display: block;
  }

  input,
  textarea {
    width: 100%;
    font-size: ${t.spacing(2)};
    border: 1px solid ${t.blue};
    line-height: 1.6;
    padding: ${t.spacing(1)};
    margin-bottom: ${t.spacing(2)};

    + div {
      color: ${t.red};
      margin-top: ${t.spacing(1)};
    }
  }

  .msg-alert {
    p {
      color: inherit !important;
    }
  }
  button {
    margin-right: ${t.spacing(1)};
    margin-bottom: ${t.spacing(2)};
    background: transparent;
    border: 1px solid ${t.text};
    padding: ${t.spacing(1)} ${t.spacing(2)};
    color: ${t.red};
    font-weight: bold;
    text-transform: uppercase;

    &[type="reset"] {
      color: ${t.text};
      opacity: 0.8;
    }
  }
  @media (min-width: ${t.desktop}) {
    max-width: 40vw;
  }
`;

const FormWrapper = styled.div`
  margin-bottom: ${t.spacing(2.5)};
`;

const StyledContactSection = styled.div`
  color: ${t.blue};
  font-size: ${t.spacing(3)};

  p {
    margin: 0;
  }
`;

const NewsPage = (props) => {
  const { register, handleSubmit, reset, errors } = useForm({ mode: "onBlur" });
  const netlify = useNetlifyForm({
    name: "react-hook-form",
    action: "/#",
    honeypotName: "bot-field",
    onSuccess: (response, context) => {
      console.log("Successfully sent form data to Netlify Server");
    },
  });
  const onSubmit = (data) => netlify.handleSubmit(null, data);
  const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i;

  return (
    <Layout location={props.location}>
      <StyledContactWrapper>
        <StyledH1>Contact</StyledH1>
        <StyledContact>
          <StyledContactSection>
            <a href="mailto:info@suetyichan.com">info@suetyichan.com</a>
          </StyledContactSection>
          {/*<NetlifyFormProvider {...netlify}>*/}
          {/*  <StyledForm onSubmit={handleSubmit(onSubmit)}>*/}
          {/*    <Honeypot />*/}
          {/*    {netlify.success && <p>Thanks for contacting us!</p>}*/}
          {/*    {netlify.error && <p>Sorry, we could not reach servers.</p>}*/}
          {/*    <FormWrapper>*/}
          {/*      <label htmlFor="name">Name:</label>*/}
          {/*      <input*/}
          {/*        type="text"*/}
          {/*        name="name"*/}
          {/*        id="name"*/}
          {/*        ref={register({*/}
          {/*          required: "Name is required",*/}
          {/*        })}*/}
          {/*      />*/}
          {/*      {errors.name && <div>{errors.name.message}</div>}*/}
          {/*    </FormWrapper>*/}
          {/*    <FormWrapper>*/}
          {/*      <label htmlFor="email">Email:</label>*/}
          {/*      <input*/}
          {/*        type="email"*/}
          {/*        name="email"*/}
          {/*        id="email"*/}
          {/*        ref={register({*/}
          {/*          required: "Email is required",*/}
          {/*          pattern: {*/}
          {/*            value: EMAIL_REGEX,*/}
          {/*            message: "Invalid email address",*/}
          {/*          },*/}
          {/*        })}*/}
          {/*      />*/}
          {/*      {errors.email && <div>{errors.email.message}</div>}*/}
          {/*    </FormWrapper>*/}
          {/*    <FormWrapper>*/}
          {/*      <label htmlFor="name">Message:</label>*/}
          {/*      <textarea*/}
          {/*        name="message"*/}
          {/*        id="message"*/}
          {/*        ref={register({*/}
          {/*          required: "Message is required",*/}
          {/*        })}*/}
          {/*      />*/}
          {/*      {errors.message && <div>{errors.message.message}</div>}*/}
          {/*    </FormWrapper>*/}
          {/*    <FormWrapper>*/}
          {/*      <button type="submit">Submit</button>*/}
          {/*      <button type="reset" onClick={() => reset()}>*/}
          {/*        Reset*/}
          {/*      </button>*/}
          {/*    </FormWrapper>*/}
          {/*  </StyledForm>*/}
          {/*</NetlifyFormProvider>*/}
        </StyledContact>
      </StyledContactWrapper>
      <StyledContactWrapper>
        <StyledH1>Newsletter</StyledH1>
        <StyledContactSection>
          <StyledForm
            action="https://suetyichan.us7.list-manage.com/subscribe/post?u=bb2a0bd0ddb6dac8b1174785c&amp;id=b0f4a42c82"
            fields={[
              {
                name: "EMAIL",
                placeholder: "Your Email address",
                type: "email",
                required: true,
              },
            ]}
          />
        </StyledContactSection>
      </StyledContactWrapper>
      <StyledContactWrapper>
        <StyledH1>Follow</StyledH1>
        <StyledContactSection>
          <a href="https://instagram.com/suetyichan" target="_blank">
            Instagram
          </a>
        </StyledContactSection>
      </StyledContactWrapper>
      <StyledContactWrapper>
        <StyledH1>Website</StyledH1>
        <StyledContactSection>
          <p>Design:</p>
          <p>Durchzwei</p>
          <p>Development:</p>
          <p>Kenny Leung</p>
        </StyledContactSection>
      </StyledContactWrapper>
    </Layout>
  );
};

export default NewsPage;
